import { Box, Button, Flex, Input, VStack, Divider, useToast } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { StyledSelect } from '@components/common/StyledSelect';
import { FormField } from '@components/FormField';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import useQueryParams from '@hooks/useQueryParams';
import { PROFILE_TYPE } from '@models/profileTypes';
import { useCreateProfileMutation, useGetProfileGroupsQuery } from '@services/canaria.services';
import { convertObjectKeysToCamelCase } from '@services/utils';

interface ProfileFormProps {
  orgId: string;
  profile?: any;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ orgId, profile }) => {
  const toast = useToast();
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  const { data: profileGroups, isLoading: isLoadingProfileGroups } = useGetProfileGroupsQuery({ orgId: activeOrgID });
  const { queryParams, setQueryParams } = useQueryParams();
  const [createProfile, { isLoading }] = useCreateProfileMutation();
  const isProfileCreated = profile != null;

  const onSubmit = async (values): Promise<object | undefined> => {
    try {
      if (!isProfileCreated) {
        const profileCreated = await createProfile({
          orgId,
          ...values,
          profileGroup: values.profileGroup.id.value,
          resourcetype: values.resourcetype.value,
          // If we are creating a profile without a parent profile, we set isPrimary:true
          isPrimary: true
        }).unwrap();
        setQueryParams({ ...queryParams, profileId: profileCreated.id, step: 2 });
      } else {
        setQueryParams({ ...queryParams, step: 2 });
      }
    } catch (error) {
      toast({
        title: 'Error creating profile',
        description: 'An unexpected error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return convertObjectKeysToCamelCase((error as { data: object }).data);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={isProfileCreated ? profile : {}}
      render={({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit} w="100%" mx="auto">
          <VStack spacing={4} align="stretch" p={4} w="100%">
            <FormField name="name" label="Profile Name" validate={['required']}>
              <Input placeholder="Profile Name" type="text" />
            </FormField>
            <Divider />
            <FormField name="resourcetype" label="Profile Type" validate={['required']}>
              <StyledSelect
                placeholder="Select profile type"
                options={[
                  { value: PROFILE_TYPE.INDIVIDUAL, label: 'Individual' },
                  { value: PROFILE_TYPE.ENTITY, label: 'Entity' }
                ]}
                isDisabled={isProfileCreated}
              />
            </FormField>
            <Divider />
            <FormField name="profileGroup.id" label="Profile Group" validate={['required']}>
              <StyledSelect
                placeholder="Select profile group"
                isDisabled={isProfileCreated}
                options={
                  isLoadingProfileGroups ? [] : profileGroups?.results?.map((group) => ({ value: group.id, label: group.name }))
                }
                isLoading={isLoadingProfileGroups}
              />
            </FormField>
            <Flex justifyContent="flex-end" gap={4} mt={8} mb={6} px={4}>
              <Link to={queryParams.from === 'profiles' ? '/dashboard/profiles' : `/dashboard/profiles/${profile.id}`}>
                <Button variant="secondaryLarge">
                  Back to {queryParams.from === 'profiles' ? 'profiles' : 'profile'}
                </Button>
              </Link>
              <Button
                type="submit"
                isLoading={isLoading}
                rightIcon={<Icon icon="ri:arrow-right-line" />}
                variant="primaryLarge"
              >
                Next
              </Button>
            </Flex>
          </VStack>
        </Box>
      )}
    />
  );
};

export default ProfileForm;
