import { useEffect, useState } from 'react';
import { Select } from 'chakra-react-select';

import { countryTransform, type FieldDefinition } from '../consts';
import { canariaApi } from '@services/canaria.services';

/**
 * DynamicQuerySelect is a specialized select component
 * to handle data fetching and custom transformation dynamically.
 *
 * Since we often need to pass options to select components
 * and in some cases, we'd need to fetch all the data on the initial render
 * of the component that has them.
 *
 * For that reason, we created this component to handle the data fetching and transformation
 * so that we can declaratively declare the fetching query and transformation function
 *
 * like this:
 *
 * ```tsx
 * const fieldData: ProfileFieldData = {
 *  query: () => useQuery('queryKey', fetchFunction),
 *  transform: (data) => data.map((item) => ({ value: item.value, label: item.name }))
 * }
 *...
 */

// this function will help us get the corresponding useQuery hook
// from the canariaApi object so that we can use it to fetch the data
// query in fieldData is a string that's a path to the useQuery hook
const getQuery = (queryName: string | undefined) => {
  if (!queryName) return null;

  // Get just the last part of the URL path
  const endpoint = queryName.split('/').filter(Boolean).pop() || '';
  const hookName = endpoint.charAt(0).toUpperCase() + endpoint.slice(1);

  // Find the endpoint that contains our hook name
  const matchingEndpoint = Object.keys(canariaApi).find((key) =>
    key.toLowerCase().includes(hookName.toLowerCase())
  );

  if (!matchingEndpoint) {
    console.warn(`No query hook found for endpoint: ${endpoint}`);
    return null;
  }

  // Get the hook from canariaApi
  const hook = canariaApi[matchingEndpoint];
  if (!hook) return null;

  // Use the hook
  return hook;
};

export const DynamicQuerySelect: React.FC<{
  value: any;
  onChange: (value: any) => void;
  fieldData: FieldDefinition;
}> = ({ value, onChange, fieldData }) => {
  const [options, setOptions] = useState<any[]>([]);

  const query = getQuery(fieldData.query);
  const { data } = query(null) || { data: null };

  useEffect(() => {
    if (!data) return;

    const processedOptions =
      fieldData.type === 'foreign_key'
        ? countryTransform(data)
        : data.map((item) => ({ value: item.value, label: item.name }));

    setOptions(processedOptions);
  }, [data, fieldData]);

  return (
    <Select
      value={options.find((opt: any) => opt.value === value)}
      onChange={onChange}
      options={options}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999
        })
      }}
    />
  );
};
