import { Box, Button, Flex, Input, VStack, Textarea, Divider, useToast } from '@chakra-ui/react';
import pick from 'lodash/pick';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { StyledSelect } from '@components/common/StyledSelect';

import { useProfileWizard } from '@context/ProfileWizardContext';
import useQueryParams from '@hooks/useQueryParams';
import { type IProfile } from '@models/profileTypes';
import { useUpdateProfileMutation, useGetCountriesQuery } from '@services/canaria.services';
import { convertObjectKeysToCamelCase } from '@services/utils';

import {
  REGULATORY_INVESTIGATION_STATUS_OPTIONS,
  LICENSING_REGISTRATION_STATUS_OPTIONS,
  AML_SANCTIONS_PROGRAM_REQUIRED_OPTIONS,
  AML_SANCTIONS_PROGRAM_ELEMENTS_OPTIONS
} from './entityOptions';
import { useFieldsConfiguration } from '@hooks/useFieldsConfiguration';
import { ConfigurableFormField } from '@components/ConfigurableFormField';

interface ProfileFormProps {
  orgId: string;
  profile: IProfile;
}

const AddBusinessRegulatoryCompliance: React.FC<ProfileFormProps> = ({ profile, orgId }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { currentStep, maxSteps } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const fieldsConfiguration = useFieldsConfiguration(profile.id as string);

  const { data: countries = [] } = useGetCountriesQuery(null);

  const onSubmit = async (values): Promise<object | undefined> => {
    try {
      await updateProfile({
        orgId,
        profileId: profile.id,
        natureOfBusiness: values.natureOfBusiness,
        highRiskJurisdictionFootprint: values.highRiskJurisdictionFootprint.map((c) => c.value),
        regulatoryInvestigationStatus: values.regulatoryInvestigationStatus.value,
        licensingRegistrationStatus: values.licensingRegistrationStatus.value,
        licensingRegistrationInformation: values.licensingRegistrationInformation,
        amlSanctionsProgramRequired: values.amlSanctionsProgramRequired.value,
        amlSanctionsProgramElements: values.amlSanctionsProgramElements.map((e) => e.value),
        amlSanctionsProgramComments: values.amlSanctionsProgramComments
      }).unwrap();

      if (currentStep === maxSteps) {
        navigate(`/dashboard/profiles/${profile.id}`);
        return;
      }
      setQueryParams({ ...queryParams, step: currentStep + 1 });
    } catch (error) {
      toast({
        title: 'Error updating profile',
        description: 'An unexpected error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
      return convertObjectKeysToCamelCase((error as { data: object }).data);
    }
  };
  return (
    <Form
      initialValues={{
        ...pick(profile, [
          'natureOfBusiness',
          'licensingRegistrationInformation',
          'amlProgramComments',
          'sanctionsProgramComments'
        ]),
        highRiskJurisdictionFootprint: profile.highRiskJurisdictionFootprint.map((country) => ({
          label: country.englishName,
          value: country.id
        })),
        regulatoryInvestigationStatus: REGULATORY_INVESTIGATION_STATUS_OPTIONS.find(
          (option) => option.value === profile.regulatoryInvestigationStatus
        ),
        licensingRegistrationStatus: LICENSING_REGISTRATION_STATUS_OPTIONS.find(
          (option) => option.value === profile.licensingRegistrationStatus
        ),
        amlSanctionsProgramRequired: AML_SANCTIONS_PROGRAM_REQUIRED_OPTIONS.find(
          (option) => option.value === profile.amlSanctionsProgramRequired
        ),
        amlSanctionsProgramElements: profile.amlSanctionsProgramElements?.map((element) => ({
          label: AML_SANCTIONS_PROGRAM_ELEMENTS_OPTIONS.find((option) => option.value === element)?.label,
          value: element
        })),
        amlSanctionsProgramComments: profile.amlSanctionsProgramComments
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <Box as="form" onSubmit={handleSubmit} w="90%">
          <VStack spacing={4} align="stretch" p={5} mt={4}>
            <ConfigurableFormField
              fieldKey="natureOfBusiness"
              fieldsConfiguration={fieldsConfiguration}
              name="natureOfBusiness"
              label="Nature of Business"
              isRequiredByDefault
              form={form}
            >
              <Textarea />
            </ConfigurableFormField>
            <Divider />
            <ConfigurableFormField
              fieldKey="highRiskJurisdictionFootprint"
              fieldsConfiguration={fieldsConfiguration}
              name="highRiskJurisdictionFootprint"
              label="High Risk Jurisdiction Footprint"
              isRequiredByDefault
              form={form}
            >
              <StyledSelect
                placeholder="Select country"
                selectedOptionStyle="check"
                options={countries?.map((country) => ({ label: country.englishName, value: country.id }))}
                isMulti
              />
            </ConfigurableFormField>
            <Divider />
            <ConfigurableFormField
              fieldKey="regulatoryInvestigationStatus"
              fieldsConfiguration={fieldsConfiguration}
              name="regulatoryInvestigationStatus"
              label="Regulatory Investigation/Enforcement Status"
              isRequiredByDefault
              form={form}
            >
              <StyledSelect placeholder="Select one..." options={REGULATORY_INVESTIGATION_STATUS_OPTIONS} />
            </ConfigurableFormField>
            <Divider />
            <ConfigurableFormField
              fieldKey="licensingRegistrationStatus"
              fieldsConfiguration={fieldsConfiguration}
              name="licensingRegistrationStatus"
              label="Licensing/Registration Status"
              isRequiredByDefault
              form={form}
            >
              <StyledSelect placeholder="Select one..." options={LICENSING_REGISTRATION_STATUS_OPTIONS} />
            </ConfigurableFormField>
            <ConfigurableFormField
              fieldKey="licensingRegistrationInformation"
              fieldsConfiguration={fieldsConfiguration}
              name="licensingRegistrationInformation"
              label="Licensing/Registration Information"
              isRequiredByDefault
              form={form}
            >
              <Input />
            </ConfigurableFormField>
            <Divider />
            <ConfigurableFormField
              fieldKey="amlSanctionsProgramRequired"
              fieldsConfiguration={fieldsConfiguration}
              name="amlSanctionsProgramRequired"
              label="AML/Sanctions Program Required?"
              isRequiredByDefault
              form={form}
            >
              <StyledSelect placeholder="Select one..." options={AML_SANCTIONS_PROGRAM_REQUIRED_OPTIONS} />
            </ConfigurableFormField>

            <ConfigurableFormField
              fieldKey="amlSanctionsProgramElements"
              fieldsConfiguration={fieldsConfiguration}
              name="amlSanctionsProgramElements"
              label="AML/Sanctions Program Elements"
              validate={[
                (value, allValues) => {
                  if (
                    allValues.amlSanctionsProgramRequired?.value === true &&
                    (value === undefined || value.length === 0)
                  ) {
                    return 'AML/Sanctions Program Elements are required when program is required';
                  }
                  return undefined;
                }
              ]}
              form={form}
            >
              <StyledSelect placeholder="Select elements..." options={AML_SANCTIONS_PROGRAM_ELEMENTS_OPTIONS} isMulti />
            </ConfigurableFormField>

            <ConfigurableFormField
              fieldKey="amlSanctionsProgramComments"
              fieldsConfiguration={fieldsConfiguration}
              name="amlSanctionsProgramComments"
              label="Additional Comments"
              form={form}
            >
              <Textarea />
            </ConfigurableFormField>

            <Flex justifyContent="flex-end" gap={4} mt={4}>
              <Button
                onClick={() => {
                  setQueryParams({ ...queryParams, step: currentStep - 1 });
                }}
                variant="secondaryLarge"
              >
                Back
              </Button>
              <Button type="submit" isLoading={isLoading} variant="primaryLarge">
                {currentStep === maxSteps ? 'Finish' : 'Next'}
              </Button>
            </Flex>
          </VStack>
        </Box>
      )}
    />
  );
};

export default AddBusinessRegulatoryCompliance;
