import { Box, Flex, FormControl, FormLabel, FormErrorMessage, type FlexProps } from '@chakra-ui/react';
import React from 'react';
import { Field, type FieldMetaState } from 'react-final-form';
import validator from 'validator';

import { validateEmail } from '@services/utils';
import { isEmptyOrNil } from '@utils/formHelpers';

type LayoutDirection = 'horizontal' | 'vertical';

export interface FormFieldProps {
  name: string;
  label: string;
  direction?: LayoutDirection;
  validate?: ValidatorType | ValidatorType[];
  parse?: (value: any) => any;
  isDisabled?: boolean;
  maxW?: string | null;
  hideLabel?: boolean;
  children:
    | React.ReactNode
    | ((props: {
        input: {
          value: any;
          onChange: (e: any) => void;
          onBlur: () => void;
          name: string;
          disabled?: boolean;
        };
        meta: FieldMetaState<any>;
      }) => React.ReactNode);
  disableOptionalText?: boolean;
}

type SimpleValidatorType = 'required' | 'email' | 'number' | 'url' | 'phone';

type ComplexValidatorType =
  | { type: 'minLength'; value: number }
  | { type: 'maxLength'; value: number }
  | { type: 'regex'; pattern: RegExp };

type ValidatorFunction = (value: any, allValues?: any) => string | undefined;

export type ValidatorType = SimpleValidatorType | ComplexValidatorType | ValidatorFunction;

type SimpleValidatorFunction = (label: string) => (value: any) => string | undefined;
type LengthValidatorFunction = (value: number) => (label: string) => (value: any) => string | undefined;
type RegexValidatorFunction = (pattern: RegExp) => (label: string) => (value: any) => string | undefined;

export const validators: Record<SimpleValidatorType, SimpleValidatorFunction> & {
  minLength: LengthValidatorFunction;
  maxLength: LengthValidatorFunction;
  regex: RegexValidatorFunction;
} = {
  required: (label: string) => (value: any) => {
    if (Array.isArray(value)) {
      return value.length === 0 ? `${label} is required` : undefined;
    }
    if (value != null && typeof value === 'object' && 'value' in value) {
      return value.value !== undefined ? undefined : `${label} is required`;
    }
    return isEmptyOrNil(value) ? `${label} is required` : undefined;
  },
  email: (label: string) => (value: string) =>
    isEmptyOrNil(value) || validateEmail(value) ? undefined : `${label} is invalid`,
  number: (label: string) => (value: string) =>
    isEmptyOrNil(value) || isNaN(Number(value)) ? `${label} must be a number` : undefined,
  url: (label: string) => (value: string) =>
    isEmptyOrNil(value) ||
    validator.isURL(value, {
      protocols: ['http', 'https'],
      require_protocol: true
    }) === true
      ? undefined
      : `${label} is invalid`,
  phone: (label: string) => (value: string) =>
    isEmptyOrNil(value) || validator.isMobilePhone(value, 'any') === true ? undefined : `${label} is invalid`,
  minLength: (min: number) => (label: string) => (value: string) =>
    isEmptyOrNil(value) || value.length < min ? `${label} must be at least ${min} characters` : undefined,

  maxLength: (max: number) => (label: string) => (value: string) =>
    isEmptyOrNil(value) || value.length > max ? `${label} must be no more than ${max} characters` : undefined,

  regex: (pattern: RegExp) => (label: string) => (value: string) =>
    isEmptyOrNil(value) || !pattern.test(value) ? `${label} is invalid` : undefined
};

export const FormField: React.FC<FormFieldProps> = ({
  name,
  label,
  direction = 'horizontal',
  validate,
  children,
  parse,
  isDisabled = false,
  maxW = '1000px',
  disableOptionalText = false,
  hideLabel = false,
  ...props
}) => {
  const combineValidators = (value: any, allValues?: any): string | undefined => {
    const validations: ValidatorType[] = [];

    if (validate !== undefined) {
      validations.push(...(Array.isArray(validate) ? validate : [validate]));
    }

    for (const validator of validations) {
      let error;

      if (typeof validator === 'function') {
        error = validator(value, allValues);
      } else if (typeof validator === 'string') {
        error = validators[validator](label)(value);
      } else if (typeof validator === 'object') {
        if (validator.type === 'minLength') {
          error = validators.minLength(validator.value)(label)(value);
        } else if (validator.type === 'maxLength') {
          error = validators.maxLength(validator.value)(label)(value);
        } else if (validator.type === 'regex') {
          error = validators.regex(validator.pattern)(label)(value);
        }
      }

      if (error !== undefined) return error;
    }

    return undefined;
  };

  const layoutStyles: Record<LayoutDirection, FlexProps> = {
    horizontal: {
      direction: 'row',
      align: 'center',
      justify: 'flex-start',
      maxW: maxW ?? undefined
    },
    vertical: {
      direction: 'column',
      align: 'flex-start',
      gap: 2
    }
  };

  const isRequired = Array.isArray(validate) && validate.includes('required' as ValidatorType);

  return (
    <Field
      name={name}
      validate={combineValidators}
      subscription={{ touched: true, error: true, submitError: true, value: true }}
      parse={parse}
      render={({ input, meta }) => {
        return (
          <FormControl
            isInvalid={meta.touched === true && (meta.error != null || meta.submitError != null)}
            isDisabled={isDisabled}
            {...props}
          >
            <Flex {...layoutStyles[direction]}>
              {!hideLabel && (
                <FormLabel
                  flex={direction === 'horizontal' ? '1' : 'initial'}
                  mb={direction === 'horizontal' ? 0 : 2}
                  opacity="1 !important"
                  style={{
                    alignSelf: 'flex-start'
                  }}
                >
                  {label}
                  <Box as="span" color={`wizard.forms.${isRequired ? 'required' : 'optional'}.text`} ml={1}>
                    {isRequired ? '*' : !disableOptionalText ? '(optional)' : ''}
                  </Box>
                </FormLabel>
              )}
              <Box
                flex={direction === 'horizontal' ? '2' : 'initial'}
                w={direction === 'vertical' ? '100%' : 'auto'}
                maxW="530px"
              >
                {typeof children === 'function'
                  ? children({
                      input: {
                        ...input,
                        disabled: isDisabled
                      },
                      meta
                    })
                  : React.cloneElement(children as React.ReactElement, {
                      ...input,
                      disabled: isDisabled,
                      'aria-disabled': isDisabled
                    })}
                {meta.touched === true && (meta.error != null || meta.submitError != null) && (
                  <FormErrorMessage>{meta.error ?? meta.submitError}</FormErrorMessage>
                )}
              </Box>
            </Flex>
          </FormControl>
        );
      }}
    />
  );
};
