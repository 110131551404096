import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Select,
  Textarea,
  Heading,
  Tooltip,
  Icon,
  Text,
  Container,
  Flex,
  Spinner,
  Center,
  IconButton,
  useToast
} from '@chakra-ui/react';
import { QuestionIcon, EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { Token } from '@models/profileTypes';
import ConditionalBuilderModal from './components/ConditionalBuilderModal';
import { type FieldDefinition } from './consts';
import {
  useGetFieldsMetadataQuery,
  useGetProfileGroupByIdQuery,
  useUpdateProfileGroupMutation
} from '@services/canaria.services';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { parseCondition } from './utils';
import { toCamelCase } from '@services/utils';

interface FieldConfig {
  enabled: boolean;
  configuration: 'required' | 'optional' | 'conditional';
  conditional: string;
  conditional_tokens: Token[];
}

interface ProfileConfig {
  [fieldId: string]: FieldConfig;
}

interface FieldsConfiguration {
  IndividualProfile?: ProfileConfig;
  EntityProfile?: ProfileConfig;
}

const FieldConfig = ({
  fieldsConfig,
  profileType,
  fieldId,
  field
}: {
  fieldsConfig: FieldsConfiguration;
  profileType: 'IndividualProfile' | 'EntityProfile';
  fieldId: string;
  field: FieldDefinition;
}) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const { profileGroupId } = useParams();
  const toast = useToast();

  if (!activeOrgID || !profileGroupId) {
    console.error('Missing required parameters');
    return null;
  }

  const initialValues = useRef({
    enabled: fieldsConfig?.[profileType]?.[toCamelCase(fieldId)]?.enabled ?? true,
    required: fieldsConfig?.[profileType]?.[toCamelCase(fieldId)]?.configuration ?? 'required',
    conditional: fieldsConfig?.[profileType]?.[toCamelCase(fieldId)]?.conditional ?? ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [enabled, setEnabled] = useState(initialValues.current.enabled);
  const [required, setRequired] = useState<'required' | 'optional' | 'conditional'>(
    initialValues.current.required as 'required' | 'optional' | 'conditional'
  );
  const [conditional, setConditional] = useState(initialValues.current.conditional);
  const [isConditionModalOpen, setIsConditionModalOpen] = useState(false);
  const [updateProfileGroup, { isLoading }] = useUpdateProfileGroupMutation();

  useEffect(() => {
    // if the fieldsConfig changes and we're not editing, then update both initialValues and the state
    if (!isEditing) {
      initialValues.current = {
        enabled: fieldsConfig?.[profileType]?.[toCamelCase(fieldId)]?.enabled ?? true,
        required: fieldsConfig?.[profileType]?.[toCamelCase(fieldId)]?.configuration ?? 'required',
        conditional: fieldsConfig?.[profileType]?.[toCamelCase(fieldId)]?.conditional ?? ''
      };
      setEnabled(initialValues.current.enabled);
      setRequired(initialValues.current.required as 'required' | 'optional' | 'conditional');
      setConditional(initialValues.current.conditional);
    }
  }, [fieldsConfig]);

  const handleSave = async () => {
    try {
      const currentFieldsConfiguration = fieldsConfig ?? { IndividualProfile: {}, EntityProfile: {} };
      const newFieldsConfiguration = {
        ...currentFieldsConfiguration,
        [profileType]: {
          ...currentFieldsConfiguration[profileType],
          [fieldId]: {
            enabled,
            configuration: required,
            conditional,
            conditional_tokens: parseCondition(conditional)
          }
        }
      };

      await updateProfileGroup({
        orgId: activeOrgID,
        profileGroupId,
        fieldsConfiguration: newFieldsConfiguration
      }).unwrap();

      initialValues.current = {
        enabled,
        required,
        conditional
      };
      setIsEditing(false);
    } catch (error) {
      toast({
        title: 'Error updating profile group',
        description: 'An unexpected error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const handleCancel = () => {
    // Safely reset to initial values
    const safeInitialValues = {
      enabled: fieldsConfig?.[profileType]?.[toCamelCase(fieldId)]?.enabled ?? true,
      required: fieldsConfig?.[profileType]?.[toCamelCase(fieldId)]?.configuration ?? 'required',
      conditional: fieldsConfig?.[profileType]?.[toCamelCase(fieldId)]?.conditional ?? ''
    };

    initialValues.current = safeInitialValues;
    setEnabled(safeInitialValues.enabled);
    setRequired(safeInitialValues.required as 'required' | 'optional' | 'conditional');
    setConditional(safeInitialValues.conditional);
    setIsEditing(false);
  };

  return (
    <Tr>
      <Td>
        <Checkbox isChecked={enabled} onChange={() => setEnabled(!enabled)} isDisabled={!isEditing} />
      </Td>
      <Td>{fieldId}</Td>
      <Td>{field.label}</Td>
      <Td>
        <Select
          size="sm"
          value={required}
          isDisabled={!isEditing || !enabled}
          onChange={(e) => setRequired(e.target.value as 'required' | 'optional' | 'conditional')}
        >
          <option value="required">Required</option>
          <option value="optional">Optional</option>
          <option value="conditional">Conditional</option>
        </Select>
      </Td>
      <Td maxW="300px">
        <Box
          onClick={() => {
            if (isEditing && enabled && required === 'conditional') {
              setIsConditionModalOpen(true);
            }
          }}
        >
          <Textarea
            placeholder="Click to edit condition..."
            size="sm"
            value={conditional}
            isReadOnly
            rows={Math.max(1, conditional.split('\n').length, Math.ceil(conditional.length / 40))}
            resize="vertical"
            cursor={isEditing && enabled && required === 'conditional' ? 'pointer' : 'not-allowed'}
            opacity={enabled && required === 'conditional' ? 1 : 0.4}
            _hover={isEditing && enabled && required === 'conditional' ? { borderColor: 'blue.500' } : undefined}
          />
        </Box>
      </Td>
      <Td>
        {!isEditing ? (
          <IconButton aria-label="Edit field" icon={<EditIcon />} size="sm" onClick={() => setIsEditing(true)} />
        ) : (
          <Flex gap={2}>
            <IconButton
              aria-label="Save changes"
              icon={<CheckIcon />}
              size="sm"
              colorScheme="green"
              onClick={handleSave}
              isLoading={isLoading}
            />
            <IconButton
              aria-label="Cancel changes"
              icon={<CloseIcon />}
              size="sm"
              colorScheme="red"
              onClick={handleCancel}
            />
          </Flex>
        )}
      </Td>
      {isConditionModalOpen && (
        <ConditionalBuilderModal
          conditional={conditional}
          isOpen={isConditionModalOpen}
          onClose={() => setIsConditionModalOpen(false)}
          onSave={(condition) => setConditional(condition)}
          selectedField={{ profileType, fieldId }}
        />
      )}
    </Tr>
  );
};

const FieldsConfig: React.FC<{ profileGroupId: string }> = ({ profileGroupId }) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const { data, isLoading } = useGetProfileGroupByIdQuery({
    orgId: activeOrgID as string,
    profileGroupId: profileGroupId
  });
  const { data: PROFILE_FIELD_CATEGORIES, isLoading: fieldCategoriesLoading } = useGetFieldsMetadataQuery(null);

  if (isLoading || fieldCategoriesLoading) {
    return (
      <Center h="200px">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Center>
    );
  }

  const fieldsConfiguration = data?.fieldsConfiguration;
  const renderProfileTable = (profileType: 'IndividualProfile' | 'EntityProfile') => {
    if (!PROFILE_FIELD_CATEGORIES) return null;

    return Object.entries(PROFILE_FIELD_CATEGORIES[profileType]).map(([category, fields]) => (
      <Box key={category} mb={6}>
        <Box position="relative" mb={4}>
          <Heading size="md">{category}</Heading>
        </Box>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Enabled</Th>
              <Th>Field ID</Th>
              <Th>Field Name</Th>
              <Th>Configuration</Th>
              <Th>Conditional Criteria</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(fields).map(([fieldId, field]) => (
              <FieldConfig
                key={fieldId}
                fieldsConfig={fieldsConfiguration}
                profileType={profileType}
                fieldId={fieldId}
                field={field}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
    ));
  };

  return (
    <Box p={4}>
      <Tabs variant="unstyled" colorScheme="gray" w="100%">
        <TabList mb="0" w="100%" position="relative" zIndex="1">
          <Tab
            flex={1}
            maxW="200px"
            h="40px"
            bg="#F7F7F7"
            color="#7C7E7E"
            borderTopRadius="md"
            borderBottom="none"
            position="relative"
            mb="-1px"
            _selected={{
              color: 'black',
              bg: 'white',
              border: '1px solid',
              borderColor: 'gray.200',
              borderBottom: 'none',
              _after: {
                content: '""',
                position: 'absolute',
                bottom: '-1px',
                left: 0,
                right: 0,
                height: '1px',
                bg: 'white',
                zIndex: 1
              }
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Individual Profile
          </Tab>
          <Tab
            flex={1}
            maxW="200px"
            h="40px"
            bg="#F7F7F7"
            color="#7C7E7E"
            borderTopRadius="md"
            borderBottom="none"
            position="relative"
            mb="-1px"
            ml={2}
            _selected={{
              color: 'black',
              bg: 'white',
              border: '1px solid',
              borderColor: 'gray.200',
              borderBottom: 'none',
              _after: {
                content: '""',
                position: 'absolute',
                bottom: '-1px',
                left: 0,
                right: 0,
                height: '1px',
                bg: 'white',
                zIndex: 1
              }
            }}
            _focus={{ boxShadow: 'none' }}
          >
            Entity Profile
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel
            p={6}
            bg="white"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            borderTopLeftRadius="0"
          >
            <Box textAlign="right">
              <Tooltip
                label={
                  <Box p={2}>
                    <Heading size="sm" mb={2}>
                      Conditional Criteria Help
                    </Heading>
                    <Text mb={2}>
                      Use conditional expressions to determine when a field should be required. You can:
                    </Text>
                    <Box as="ul" pl={4}>
                      <li>
                        Compare field values: <code>field_name == "value"</code>
                      </li>
                      <li>
                        Use numeric comparisons: <code>average_monthly_txn &gt; 1000</code>
                      </li>
                      <li>
                        Combine conditions: <code>field1 == true &amp;&amp; field2 &gt;= 100</code>
                      </li>
                    </Box>
                  </Box>
                }
                placement="top"
                hasArrow
              >
                <Icon as={QuestionIcon} ml={2} cursor="help" />
              </Tooltip>
            </Box>
            {renderProfileTable('IndividualProfile')}
          </TabPanel>
          <TabPanel
            p={6}
            bg="white"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            borderTopLeftRadius="0"
          >
            <Box textAlign="right">
              <Tooltip
                label={
                  <Box p={2}>
                    <Heading size="sm" mb={2}>
                      Conditional Criteria Help
                    </Heading>
                    <Text mb={2}>
                      Use conditional expressions to determine when a field should be required. You can:
                    </Text>
                    <Box as="ul" pl={4}>
                      <li>
                        Compare field values: <code>field_name == "value"</code>
                      </li>
                      <li>
                        Use numeric comparisons: <code>average_monthly_txn &gt; 1000</code>
                      </li>
                      <li>
                        Combine conditions: <code>field1 == true &amp;&amp; field2 &gt;= 100</code>
                      </li>
                    </Box>
                  </Box>
                }
                placement="top"
                hasArrow
              >
                <Icon as={QuestionIcon} ml={2} cursor="help" />
              </Tooltip>
            </Box>
            {renderProfileTable('EntityProfile')}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const ProfileGroupDetails = () => {
  const { profileGroupId } = useParams();

  return (
    <Container maxW="8xl" px={10}>
      <Flex justifyContent="space-between" mx="4" mb={5}>
        <Heading as="h1" size="lg" textAlign="left" mb={5} fontWeight="normal">
          Profile Group Details
        </Heading>
      </Flex>

      <Tabs variant="enclosed">
        <TabList>
          <Tab
            bg="gray.50"
            _selected={{
              bg: 'white',
              borderBottom: 'none',
              borderTop: '1px solid',
              borderLeft: '1px solid',
              borderRight: '1px solid',
              borderColor: 'gray.200',
              mb: '-1px'
            }}
          >
            Fields Configuration
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} bg="white" borderX="1px solid" borderBottom="1px solid" borderColor="gray.200">
            <Box pt={6}>
              <FieldsConfig profileGroupId={profileGroupId as string} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default ProfileGroupDetails;
