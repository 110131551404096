export interface ProfileGroup {
  name: string;
  description: string;
}

export interface IExpectedActivity {
  purposeForEstablishingAccount: string;
  expectedNatureOfActivity: string;
  sourceOfFunds: string;
  flowOfFunds: string;
  activityType: 'one-time' | 'ongoing';
  averageMonthlyTxn: string;
  averageMonthlyValueTxn: string;
  expectedTransactionActivityComments: string;
}

export interface Token {
  type: 'field' | 'value' | 'operator';
  value: string;
}

export interface FieldConfiguration {
  enabled?: boolean;
  conditional?: string;
  configuration?: 'optional' | 'required' | 'conditional';
  conditionalTokens?: Token[];
}

export interface IRootProfile extends IExpectedActivity {
  id: string;
  name: string;
  profileGroup: {
    id: string;
    name: string;
    stepsProfileForm: {
      steps: Array<{
        step: 'Related Parties' | 'Business Regulatory Compliance' | 'Expected Transaction Activity' | 'Add Wallets';
        entity: boolean;
        individual: boolean;
      }>;
    };
    fieldsConfiguration: {
      EntityProfile: Record<string, FieldConfiguration>;
      IndividualProfile: Record<string, FieldConfiguration>;
    };
  };
  relatedProfiles: any[];
  status: PROFILE_STATUS_CODE;
  resourcetype: PROFILE_TYPE;
  risk: string;
  hasOpenItems: boolean;
  totalUnresolvedHits: number;
  attachments: Array<{
    id: string;
    file: string;
    name: string;
    notes: string;
    createdAt: string;
  }>;
  pepDeclaration: boolean;
  pepPosition: string;
  pepCountry: {
    id: number;
    englishName: string;
  };
  pointOfContactName: string;
  createdAt: string;
  updatedAt: string;
  projectName: string;
  organizationContact: string;
}

export interface IIndividualProfile {
  id: string;
  fullLegalName: string;
  email: string;
  gender: {
    value: string;
    name: string;
  };
  dateOfBirth: string;
  address: string;
  state: string;
  city: string;
  postalCode: string;
  idNumber: string;
  idType: {
    value: string;
    name: string;
  };
  natureOfEmployment: string;
  citizenship: {
    id: number;
    englishName: string;
  };
  countryOfResidence: {
    id: number;
    englishName: string;
  };
  idIssuer: {
    id: number;
    englishName: string;
  };
  placeOfBirth: {
    id: number;
    englishName: string;
  };
  controlPersonTitle: string;
  ownershipPercentage: string;
  isPrimary: boolean;
  relationshipToPrimaryParty: string;
}

export interface IEntityProfile {
  fullLegalName: string;
  dbaTradeName: string;
  entityFormationType: string;
  placeOfEstablishment: {
    id: number;
    englishName: string;
  };
  dateOfEstablishment: string;
  uniqueIdentificationNumber: string;
  registeredBusinessAddress: string;
  mailingAddress: string;
  physicalAddress: string;
  companyWebsite: string;
  natureOfBusiness: string;
  highRiskJurisdictionFootprint: Array<{
    id: number;
    englishName: string;
  }>;
  regulatoryInvestigationStatus: string;
  regulatoryInvestigationDetails: string;
  licensingRegistrationStatus: string;
  licensingRegistrationInformation: string;
  amlSanctionsProgramRequired: boolean;
  amlSanctionsProgramElements: string[];
  amlSanctionsProgramComments: string;
  businessOperationsFootprint: Array<{
    id: number;
    englishName: string;
  }>;
  ageEligibilityCompliance: boolean;
  privacyComplianceExistence: boolean;
  complianceProgramDeclaration: boolean;
  relationshipToPrimaryParty: string;
  pepName: string;
  isPrimary: boolean;
}

export type IProfile = IRootProfile & IIndividualProfile & IEntityProfile;

type IUpdateEntityProfile = Partial<IEntityProfile>;

type IUpdateExpectedActivity = Partial<IExpectedActivity>;

export type IUpdateProfile = {
  name?: string;
  profileGroup?: string;
  risk?: string;
  status?: PROFILE_STATUS_CODE;
  fullLegalName?: string;
  email?: string;
  gender?: string;
  dateOfBirth?: string;
  citizenship?: string;
  state?: string;
  city?: string;
  address?: string;
  placeOfBirth?: string;
  postalCode?: string;
  countryOfResidence?: string;
  idIssuer?: string;
  idType?: string;
  idNumber?: string;
  natureOfEmployment?: string;
  controlPersonTitle?: string;
  ownershipPercentage?: string;
  pepDeclaration?: string;
  pepPosition?: string;
  pepCountry?: string;
  pointOfContactName?: string;
  pepName?: string;
  relationshipToPrimaryParty?: string;
  projectName?: string;
  organizationContact?: string;
} & IUpdateExpectedActivity &
  IUpdateEntityProfile;

export enum PROFILE_STATUS_DESCRIPTION {
  PR = 'Pending Review',
  UR = 'Under Review',
  RS = 'RFI Sent',
  RR = 'RFI Responded - Pending Review',
  ES = 'Escalated',
  AP = 'Approved',
  DE = 'Declined',
  AR = 'Archived'
}

export enum PROFILE_STATUS_CODE {
  PENDING_REVIEW = 'PR',
  UNDER_REVIEW = 'UR',
  RFI_SENT = 'RS',
  RFI_RESPONDED = 'RR',
  ESCALATED = 'ES',
  APPROVED = 'AP',
  DECLINED = 'DE',
  ARCHIVED = 'AR'
}

export enum PROFILE_TYPE {
  INDIVIDUAL = 'IndividualProfile',
  ENTITY = 'EntityProfile'
}
