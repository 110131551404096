import { Flex, Text, Box } from '@chakra-ui/react';
import { Icon } from '@iconify/react';

import { useProfileWizard } from '@context/ProfileWizardContext';
import useQueryParams from '@hooks/useQueryParams';
import './index.css';

interface StepperProps {
  maxSteps: number;
  currentStep: number;
  optionalSteps: boolean[];
}

const Step: React.FC<{
  title: string;
  isCompleted: boolean;
  isCurrent: boolean;
  isOptional: boolean;
  onClick?: () => void;
}> = ({ title, isCompleted, isCurrent, isOptional, onClick }) => {
  return (
    <Box flex="1" cursor="pointer" transition="all 0.2s" _hover={{ opacity: 0.8 }} onClick={onClick}>
      <Box
        h="8px"
        bg={isCompleted ? 'stepper.completed' : isCurrent ? 'stepper.completed' : 'stepper.pending'}
        mb={2}
        w="100%"
        borderRadius="4px"
      />
      <Flex alignItems="center" justifyContent="center" gap={2}>
        {isCompleted && (
          <Icon
            icon="lucide:check"
            style={{ color: '#BED903', fontWeight: 'bold', minWidth: '16px', minHeight: '16px' }}
          />
        )}
        <Text
          fontSize="md"
          fontWeight="medium"
          color={isCurrent ? 'stepper.text.completed' : isCompleted ? 'stepper.text.completed' : 'stepper.text.pending'}
        >
          {title}
        </Text>
      </Flex>
    </Box>
  );
};

const Stepper: React.FC<StepperProps> = ({ maxSteps, currentStep, optionalSteps }) => {
  const { stepTitles } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();

  const steps = Array.from({ length: maxSteps }, (_, i) => i + 1);

  return (
    <Box mx={4} my={4}>
      <Flex align="start" justify="space-between" mt={2} w="100%" gap={4}>
        {steps.map((step) => (
          <Step
            key={step}
            title={stepTitles[step - 1]}
            isCompleted={currentStep > step}
            isCurrent={currentStep === step}
            isOptional={optionalSteps[step - 1]}
            onClick={() => {
              if (queryParams.profileId != null) {
                setQueryParams({ ...queryParams, step });
              }
            }}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default Stepper;
