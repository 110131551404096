import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  VStack,
  HStack,
  Input,
  Portal,
  Text,
  Center,
  Spinner
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useState, memo, useCallback, useMemo, useEffect } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  MeasuringStrategy
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '@iconify/react';
import { debounce } from 'lodash';
import { ParsingToken, tokenize } from '../utils';
import { DynamicQuerySelect } from './DynamicQuerySelect';
import { useGetFieldsMetadataQuery } from '@services/canaria.services';
import { FieldDefinition, FieldType } from '../consts';

export interface SelectedField {
  profileType: 'IndividualProfile' | 'EntityProfile';
  fieldId: string;
}

interface ConditionalBuilderModalProps {
  conditional: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (condition: string) => void;
  selectedField: SelectedField | null;
}

interface Condition {
  type: 'condition';
  field: string;
  operator: string;
  value: string | number | boolean | null;
}

type RowType = Condition | 'AND' | 'OR' | '(' | ')';

interface OperatorMap {
  [key: string]: {
    [key: string]: string;
  };
}

const TYPE_OPERATORS: OperatorMap = {
  string: {
    equals: '==',
    notEquals: '!='
  },
  number: {
    equals: '==',
    notEquals: '!=',
    greaterThan: '>',
    lessThan: '<',
    greaterThanOrEqual: '>=',
    lessThanOrEqual: '<='
  },
  boolean: {
    equals: '==',
    notEquals: '!='
  },
  choice: {
    equals: '==',
    notEquals: '!='
  },
  foreign_key: {
    equals: '==',
    notEquals: '!='
  }
};

const SortableRow = memo(
  ({
    row,
    index,
    onRemove,
    onUpdate,
    onOperatorToggle,
    availableFields,
    getFieldType,
    getFieldData
  }: {
    row: RowType;
    index: number;
    onRemove: () => void;
    onUpdate: (updates: Partial<Condition>) => void;
    onOperatorToggle?: () => void;
    availableFields: Array<{ id: string; label: string }>;
    getFieldType: (fieldId: string) => string | null;
    getFieldData: (fieldId: string) => FieldDefinition | null;
  }) => {
    const [localValue, setLocalValue] = useState<string | number | boolean | null>(
      typeof row === 'string' ? null : row.value
    );
    const [localField, setLocalField] = useState(typeof row !== 'string' ? row.field : '');
    const [localOperator, setLocalOperator] = useState(typeof row !== 'string' ? row.operator : 'equals');

    useEffect(() => {
      if (typeof row !== 'string') {
        setLocalValue(row.value);
        setLocalField(row.field);
        setLocalOperator(row.operator);
      }
    }, [row]);

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: `row-${index}`
    });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      height: isDragging ? 'auto' : undefined,
      zIndex: isDragging ? 1 : undefined,
      position: 'relative' as const,
      touchAction: 'none' as const
    };

    const handleFieldChange = (newValue: any) => {
      setLocalField(newValue?.value || '');
      onUpdate({
        field: newValue?.value || '',
        operator: 'equals',
        value: ''
      });
    };

    const handleOperatorChange = (newValue: any) => {
      setLocalOperator(newValue?.value);
      onUpdate({
        operator: newValue?.value
      });
    };

    const debouncedUpdate = useCallback(
      debounce((value: string) => {
        onUpdate({ value });
      }, 300),
      [onUpdate]
    );

    const handleValueChange = (value: string) => {
      setLocalValue(value);
      debouncedUpdate(value);
    };

    if (row === '(' || row === ')') {
      return (
        <Box ref={setNodeRef} style={style} {...attributes} cursor="grab" py={1} position="relative">
          <Icon
            icon="mdi:trash-can-outline"
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              cursor: 'pointer',
              zIndex: 2
            }}
            onClick={onRemove}
            width="20"
            height="20"
            color="#718096"
          />
          <Button width="100%" variant="ghost" size="sm" {...listeners} position="relative" zIndex={1}>
            {row}
          </Button>
        </Box>
      );
    }

    if (row === 'AND' || row === 'OR') {
      return (
        <Box ref={setNodeRef} style={style} {...attributes} cursor="grab" py={1} position="relative">
          <Icon
            icon="lsicon:switch-outline"
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              cursor: 'pointer',
              zIndex: 2
            }}
            onClick={onOperatorToggle}
            width="20"
            height="20"
            color="#718096"
          />
          <Button width="100%" variant="ghost" size="sm" {...listeners} position="relative" zIndex={1}>
            {row}
          </Button>
        </Box>
      );
    }

    const renderValueInput = () => {
      if (typeof row === 'string') return null;

      const fieldType = getFieldType(localField);
      const fieldData = getFieldData(localField);

      if (!fieldType) return null;

      const commonInputStyles = {
        width: '100%',
        minWidth: '250px',
        maxWidth: '250px'
      };

      switch (fieldType) {
        case 'string':
          return (
            <Input
              value={localValue as string}
              onChange={(e) => handleValueChange(e.target.value)}
              placeholder="Enter value"
              sx={commonInputStyles}
            />
          );
        case 'number':
          return (
            <Input
              type="number"
              value={localValue as string}
              onChange={(e) => handleValueChange(e.target.value)}
              placeholder="Enter number"
              sx={commonInputStyles}
            />
          );
        case 'boolean':
          return (
            <Select
              value={{
                value: localValue,
                label:
                  localValue != null ? String(localValue).charAt(0).toUpperCase() + String(localValue).slice(1) : null
              }}
              onChange={(newValue: any) => handleValueChange(newValue?.value)}
              options={[
                { value: true, label: 'True' },
                { value: false, label: 'False' }
              ]}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999
                }),
                control: (base) => ({
                  ...base,
                  ...commonInputStyles
                })
              }}
            />
          );
        case 'foreign_key':
        case 'choice':
          if (fieldData?.query) {
            return (
              <DynamicQuerySelect
                value={localValue}
                onChange={(newValue: any) => handleValueChange(newValue?.value)}
                fieldData={fieldData}
              />
            );
          }
          return null;
        default:
          return null;
      }
    };

    const correspondingField = availableFields.find((field) => field.id === localField);

    console.log(localField, localOperator, localValue);
    console.log(getFieldType(localField), TYPE_OPERATORS);

    return (
      <Box
        ref={setNodeRef}
        style={style}
        p={2}
        border="1px"
        borderColor="gray.200"
        borderRadius="md"
        position="relative"
        bg="white"
        boxShadow={isDragging ? 'lg' : undefined}
        {...attributes}
      >
        <Icon
          icon="mdi:trash-can-outline"
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            cursor: 'pointer'
          }}
          onClick={onRemove}
          width="20"
          height="20"
          color="#718096"
        />
        <HStack spacing={2} flexWrap="wrap">
          <Box cursor="grab" p={2} {...listeners}>
            ⋮⋮
          </Box>
          <Box flex="1" maxW="250px" minW="250px">
            <Select
              value={{
                value: correspondingField?.id,
                label: correspondingField?.label
              }}
              onChange={handleFieldChange}
              options={availableFields.map((field) => ({
                value: field.id,
                label: field.label
              }))}
              placeholder="Select field"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999
                }),
                control: (base) => ({
                  ...base,
                  minWidth: '250px'
                })
              }}
            />
          </Box>
          <Box flex="1" maxW="250px" minW="250px">
            <Select
              value={{
                value: localOperator,
                label: localOperator.replace(/([A-Z])/g, ' $1').toLowerCase()
              }}
              onChange={handleOperatorChange}
              options={Object.keys(TYPE_OPERATORS[getFieldType(localField) || 'string']).map((op) => ({
                value: op,
                label: op.replace(/([A-Z])/g, ' $1').toLowerCase()
              }))}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999
                }),
                control: (base) => ({
                  ...base,
                  minWidth: '250px'
                })
              }}
            />
          </Box>
          <Box flex="1" maxW="250px">
            {renderValueInput()}
          </Box>
        </HStack>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.row === nextProps.row &&
      prevProps.index === nextProps.index &&
      prevProps.availableFields === nextProps.availableFields
    );
  }
);

const TokensToRows = (tokens: ParsingToken[]): RowType[] => {
  const rows: RowType[] = [];
  let currentCondition: (Partial<Condition> & { type: 'condition' }) | null = null;

  for (const token of tokens) {
    switch (token.type) {
      case 'paren':
        rows.push(token.value as '(' | ')');
        break;

      case 'operator':
        if (token.value === '&&' || token.value === '||') {
          if (currentCondition) {
            rows.push(currentCondition as Condition);
            currentCondition = null;
          }
          rows.push(token.value === '&&' ? 'AND' : 'OR');
        } else if (currentCondition) {
          currentCondition.operator =
            Object.entries(TYPE_OPERATORS.string).find(([_, symbol]) => symbol === token.value)?.[0] || 'equals';
        }
        break;

      case 'field':
        if (currentCondition) {
          rows.push(currentCondition as Condition);
        }
        currentCondition = {
          type: 'condition',
          field: String(token.value),
          operator: 'equals',
          value: ''
        };
        break;

      case 'value':
        if (currentCondition) {
          currentCondition.value = token.value;
          rows.push(currentCondition as Condition);
          currentCondition = null;
        }
        break;
    }
  }

  if (currentCondition) {
    rows.push(currentCondition as Condition);
  }

  return rows;
};

const getValidationError = (rows: RowType[]): string | null => {
  if (rows.length === 0) {
    return 'Please add at least one condition';
  }

  // Check for incomplete conditions
  const conditions = rows.filter((row): row is Condition => typeof row !== 'string');
  const incompleteCondition = conditions.find(
    (condition) =>
      !condition.field ||
      !condition.operator ||
      condition.value === null ||
      condition.value === undefined ||
      condition.value === ''
  );
  if (incompleteCondition) {
    return 'All conditions must have a field, operator, and value';
  }

  // Check parentheses and structure
  let parenCount = 0;
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const nextRow = rows[i + 1];

    if (row === '(') parenCount++;
    if (row === ')') parenCount--;

    if (parenCount < 0) {
      return 'Invalid parentheses: closing parenthesis before opening one';
    }

    if (i < rows.length - 1) {
      if ((row === 'AND' || row === 'OR') && (nextRow === 'AND' || nextRow === 'OR')) {
        return 'Invalid structure: cannot have two operators in a row';
      }
      if (row === '(' && (nextRow === 'AND' || nextRow === 'OR')) {
        return 'Invalid structure: cannot have operator after opening parenthesis';
      }
      if ((row === 'AND' || row === 'OR') && nextRow === ')') {
        return 'Invalid structure: cannot have operator before closing parenthesis';
      }
    }
  }

  if (parenCount !== 0) {
    return 'Invalid parentheses: unmatched parentheses';
  }

  return null;
};

const ConditionalBuilderModal: React.FC<ConditionalBuilderModalProps> = ({
  conditional,
  isOpen,
  onClose,
  onSave,
  selectedField
}) => {
  const { data: PROFILE_FIELD_CATEGORIES, isLoading: fieldCategoriesLoading } = useGetFieldsMetadataQuery(null);

  if (fieldCategoriesLoading) {
    return (
      <Center h="200px">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Center>
    );
  }
  // Validate required props
  if (!selectedField) {
    console.error('selectedField is required');
    return null;
  }

  // Safe initialization with error handling for invalid tokens
  const initializeRows = (conditional: string): RowType[] => {
    try {
      return TokensToRows(tokenize(conditional));
    } catch (error) {
      console.error('Failed to parse conditional:', error);
      return [];
    }
  };

  const [rows, setRows] = useState<RowType[]>(initializeRows(conditional));

  const availableFields = useMemo(() => {
    if (!selectedField) return [];

    const profileCategories = PROFILE_FIELD_CATEGORIES?.[selectedField.profileType] || {};

    return Object.entries(profileCategories).reduce(
      (acc, [_, categoryFields]) => {
        const fields = Object.entries(categoryFields)
          // Ensure we don't create self-referential conditions
          .filter(([fieldId]) => fieldId !== selectedField.fieldId)
          .map(([fieldId, field]) => ({
            id: fieldId,
            label: field.label
          }));
        return [...acc, ...fields];
      },
      [] as Array<{ id: string; label: string }>
    );
  }, [selectedField]);

  const getFieldType = useCallback(
    (fieldId: string): FieldType | null => {
      if (!selectedField || !fieldId) return null;

      const profileCategories = PROFILE_FIELD_CATEGORIES?.[selectedField.profileType] || {};

      for (const category of Object.values(profileCategories)) {
        const field = category[fieldId];
        if (field) {
          return field.type;
        }
      }
      return null;
    },
    [selectedField]
  );

  const getFieldData = useCallback(
    (fieldId: string): FieldDefinition | null => {
      if (!selectedField || !fieldId) return null;

      const profileCategories = PROFILE_FIELD_CATEGORIES?.[selectedField.profileType] || {};

      for (const category of Object.values(profileCategories)) {
        const field = category[fieldId];
        if (field) {
          return field;
        }
      }
      return null;
    },
    [selectedField]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setRows((rows) => {
        const oldIndex = parseInt(active.id.split('-')[1]);
        const newIndex = parseInt(over.id.split('-')[1]);

        return arrayMove(rows, oldIndex, newIndex);
      });
    }
  };

  const addCondition = () => {
    setRows((currentRows) => {
      const newCondition: RowType = {
        type: 'condition',
        field: '',
        operator: 'equals',
        value: ''
      };

      if (currentRows.length === 0) {
        return [newCondition];
      }

      return [...currentRows, 'AND', newCondition];
    });
  };

  const toggleOperator = (index: number) => {
    setRows((currentRows) => {
      const newRows = [...currentRows];
      newRows[index] = newRows[index] === 'AND' ? 'OR' : 'AND';
      return newRows;
    });
  };

  const findMatchingParenthesis = (rows: RowType[], startIndex: number, isOpening: boolean) => {
    let count = 0;
    const direction = isOpening ? 1 : -1;

    for (let i = startIndex; i >= 0 && i < rows.length; i += direction) {
      if (rows[i] === '(') count += direction;
      if (rows[i] === ')') count -= direction;
      if (count === 0) return i;
    }
    return -1;
  };

  const removeCondition = (index: number) => {
    setRows((currentRows) => {
      const newRows = [...currentRows];

      if (newRows[index] === '(' || newRows[index] === ')') {
        const isOpening = newRows[index] === '(';
        const matchingIndex = findMatchingParenthesis(newRows, index, isOpening);

        if (matchingIndex !== -1) {
          const firstIndex = Math.min(index, matchingIndex);
          const secondIndex = Math.max(index, matchingIndex);
          newRows.splice(secondIndex, 1);
          newRows.splice(firstIndex, 1);
        } else {
          newRows.splice(index, 1);
        }
      } else if (typeof newRows[index] === 'string') {
        newRows.splice(index, 1);
      } else {
        if (index > 0 && (newRows[index - 1] === 'AND' || newRows[index - 1] === 'OR')) {
          newRows.splice(index - 1, 2);
        } else if (index < newRows.length - 1 && (newRows[index + 1] === 'AND' || newRows[index + 1] === 'OR')) {
          newRows.splice(index, 2);
        } else {
          newRows.splice(index, 1);
        }
      }
      return newRows;
    });
  };

  const updateCondition = (index: number, updates: Partial<Condition>) => {
    setRows((currentRows) => {
      const newRows = [...currentRows];
      if (typeof newRows[index] !== 'string') {
        newRows[index] = {
          ...newRows[index],
          ...updates
        };
      }
      return newRows;
    });
  };

  const addParentheses = () => {
    setRows((currentRows) => [...currentRows, '(', ')']);
  };

  const buildConditionString = useCallback(
    (rows: RowType[]): string => {
      try {
        return rows
          .map((row, index) => {
            if (typeof row === 'string') {
              if (row === '(' || row === ')') return row;
              if (row === 'AND') return ' && ';
              if (row === 'OR') return ' || ';
              return ` ${row} `;
            }

            const { field, operator, value } = row;
            const fieldType = getFieldType(field);
            if (!fieldType) {
              return null;
            }

            const formattedValue = formatValue(fieldType, value);
            const operatorSymbol = TYPE_OPERATORS[fieldType]?.[operator];
            if (!operatorSymbol) {
              return null;
            }

            return `${field} ${operatorSymbol} ${formattedValue}`;
          })
          .join('');
      } catch (error) {
        console.error('Failed to build condition string:', error);
        return '';
      }
    },
    [getFieldType]
  );

  // Helper function to format values based on field type
  const formatValue = (fieldType: FieldType, value: any): string => {
    switch (fieldType) {
      case 'number':
        return String(value);
      case 'boolean':
        return String(value);
      case 'choice':
      case 'foreign_key':
        return `"${value}"`;
      default:
        throw new Error(`Unsupported field type: ${fieldType}`);
    }
  };

  const validationError = useMemo(() => getValidationError(rows), [rows]);

  const handleSave = () => {
    try {
      const conditionString = buildConditionString(rows);
      if (!conditionString) {
        throw new Error('Failed to build valid condition string');
      }
      onSave(conditionString);
      onClose();
    } catch (error) {
      console.error('Failed to save condition:', error);
      // Could add error toast here
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" blockScrollOnMount={false}>
      <ModalOverlay bg="blackAlpha.300" />
      <Portal>
        <ModalContent>
          <ModalHeader>Build Condition</ModalHeader>
          <Box px={6} pb={4} pt={0}>
            <Box
              p={3}
              border="1px"
              borderColor={validationError ? 'red.300' : 'gray.200'}
              borderRadius="md"
              bg="gray.50"
              fontSize="sm"
              fontFamily="mono"
              minH="2.5rem"
              wordBreak="break-word"
            >
              {buildConditionString(rows) || 'No condition built yet'}
            </Box>
            {validationError && (
              <HStack color="red.500" fontSize="sm" mt={2} spacing={1} align="center">
                <Icon icon="mdi:alert-circle" width="20" height="20" />
                <Text>{validationError}</Text>
              </HStack>
            )}
          </Box>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                measuring={{
                  droppable: {
                    strategy: MeasuringStrategy.Always
                  }
                }}
              >
                <SortableContext items={rows.map((_, index) => `row-${index}`)} strategy={verticalListSortingStrategy}>
                  <VStack spacing={2} align="stretch">
                    {rows.map((row, index) => (
                      <SortableRow
                        key={`row-${index}`}
                        row={row}
                        index={index}
                        onRemove={() => removeCondition(index)}
                        onUpdate={(updates) => updateCondition(index, updates)}
                        onOperatorToggle={() => toggleOperator(index)}
                        availableFields={availableFields}
                        getFieldType={getFieldType}
                        getFieldData={getFieldData}
                      />
                    ))}
                    <HStack spacing={2}>
                      <Button
                        onClick={addCondition}
                        variant="ghost"
                        height="80px"
                        flex="1"
                        border="2px dashed"
                        borderColor="gray.200"
                        _hover={{
                          borderColor: 'blue.500',
                          bg: 'gray.50'
                        }}
                        leftIcon={<Icon icon="mdi:plus" width="20" height="20" />}
                      >
                        Add Condition
                      </Button>
                      <Button
                        onClick={addParentheses}
                        variant="ghost"
                        height="80px"
                        flex="1"
                        border="2px dashed"
                        borderColor="gray.200"
                        _hover={{
                          borderColor: 'blue.500',
                          bg: 'gray.50'
                        }}
                        leftIcon={<Icon icon="mdi:code-brackets" width="20" height="20" />}
                      >
                        Add Parentheses
                      </Button>
                    </HStack>
                  </VStack>
                </SortableContext>
              </DndContext>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave} isDisabled={!!validationError}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Portal>
    </Modal>
  );
};

export default ConditionalBuilderModal;
