export interface ParsingToken {
  type: 'field' | 'value' | 'operator' | 'paren';
  value: string | number | boolean;
}

function handleOperatorStack(
  operatorStack: ParsingToken[],
  output: ParsingToken[],
  token: ParsingToken,
  precedence: Record<string, number>
): void {
  while (
    operatorStack.length > 0 &&
    operatorStack[operatorStack.length - 1].type === 'operator' &&
    precedence[operatorStack[operatorStack.length - 1].value as string] >= precedence[token.value as string]
  ) {
    const operator = operatorStack.pop();
    if (operator != null) {
      output.push(operator);
    }
  }
  operatorStack.push(token);
}

export function toPostfix(tokens: ParsingToken[]): ParsingToken[] {
  const output: ParsingToken[] = [];
  const operatorStack: ParsingToken[] = [];
  const precedence: Record<string, number> = {
    '||': 1,
    or: 1,
    '&&': 2,
    and: 2,
    '==': 3,
    '!=': 3,
    '>': 4,
    '<': 4,
    '>=': 4,
    '<=': 4
  };

  tokens.forEach((token) => {
    if (token.type === 'field' || token.type === 'value') {
      output.push(token);
    } else if (token.type === 'operator') {
      handleOperatorStack(operatorStack, output, token, precedence);
    } else if (token.value === '(') {
      operatorStack.push(token);
    } else if (token.value === ')') {
      while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1]?.value !== '(') {
        const operator = operatorStack.pop();
        if (operator != null) output.push(operator);
      }
      operatorStack.pop(); // Remove the '('
    }
  });

  while (operatorStack.length > 0) {
    const operator = operatorStack.pop();
    if (operator != null) output.push(operator);
  }

  return output;
}

function categorizeToken(token: string): ParsingToken {
  const stringRegex = /^["'].*["']$/;
  const numberRegex = /^\d+$/;

  if (stringRegex.exec(token) !== null) {
    return { type: 'value', value: token.slice(1, -1) };
  }
  if (numberRegex.exec(token) !== null) {
    return { type: 'value', value: Number(token) };
  }
  if (['true', 'false'].includes(token.toLowerCase())) {
    return { type: 'value', value: token.toLowerCase() === 'true' };
  }
  if (['==', '!=', '>', '<', '>=', '<=', '&&', '||', 'and', 'or'].includes(token)) {
    const normalizedToken = token === 'and' ? '&&' : token === 'or' ? '||' : token;
    return { type: 'operator', value: normalizedToken };
  }
  if (['(', ')'].includes(token)) {
    return { type: 'paren', value: token };
  }
  return { type: 'field', value: token };
}

export function tokenize(expr: string): ParsingToken[] {
  const tokenPattern = /(==|!=|>=|<=|>|<|&&|\|\||and|or|\(|\))|("[^"]*"|'[^']*'|\b\w+\b)/g;
  const tokens: ParsingToken[] = [];
  let match: RegExpExecArray | null = null;

  while ((match = tokenPattern.exec(expr)) !== null) {
    const token = match[0].trim();
    if (token != null) {
      tokens.push(categorizeToken(token));
    }
  }
  return tokens;
}

export function parseCondition(expression: string): ParsingToken[] {
  const tokens = tokenize(expression);
  return toPostfix(tokens);
}
