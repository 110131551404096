import { Box, Button, Flex } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useProfileWizard } from '@context/ProfileWizardContext';
import { RelatedProfileManager } from '@features/profile-wizard/';
import useQueryParams from '@hooks/useQueryParams';
import { type IProfile } from '@models/profileTypes';
import { useGetNestedRelatedProfilesQuery } from '@services/canaria.services';
interface ProfileFormProps {
  orgId: string;
  profile: IProfile;
}

const AddRelatedParty: React.FC<ProfileFormProps> = ({ orgId, profile }) => {
  const navigate = useNavigate();
  const { currentStep, maxSteps } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();

  const [showForm, setShowForm] = useState<boolean>(false);

  const { data: dataNestedRelatedProfiles, isLoading: isLoadingNestedRelatedProfiles } =
    useGetNestedRelatedProfilesQuery({
      orgId,
      profileId: profile.id
    });

  const AddRelatedPartyButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <Box display="flex" justifyContent="center" mt={10}>
      <Button
        bg="wizard.addRelatedParty.button.bg"
        color="wizard.addRelatedParty.button.color"
        onClick={onClick}
        leftIcon={<Icon icon="ri:add-line" />}
      >
        Add related party
      </Button>
    </Box>
  );

  return (
    <Box w="100%" p={4}>
      {dataNestedRelatedProfiles?.results?.length > 0 && !showForm && (
        <Box w="100%" display="flex" justifyContent="flex-end" mb={4}>
          <Button
            onClick={() => setShowForm(true)}
            leftIcon={<Icon icon="ri:add-line" />}
            variant="secondary"
          >
            Add Related Party
          </Button>
        </Box>
      )}
      <RelatedProfileManager orgId={orgId} profile={profile} showForm={showForm} setShowForm={setShowForm}>
        <Box width="fit-content" marginX="auto" minH="36" mt="24">
          <Box textAlign="center" mb={4}>
            <Box as="h2" fontSize="xl" fontWeight="bold" mb={2} color="title">
              Add Related Party
            </Box>
            <Box color="description" mb={4}>
              Add related organisations, controlling owners and ultimate beneficials (UBOs). You can skip this step by
              clicking &apos;Next&apos; if there are no related parties to add.
            </Box>
          </Box>
          <AddRelatedPartyButton onClick={() => setShowForm(true)} />
        </Box>
      </RelatedProfileManager>
      {!showForm && (
        <Flex justifyContent="flex-end" gap={4} mt={4}>
          <Button
            onClick={() => {
              setQueryParams({ ...queryParams, step: currentStep - 1 });
            }}
            leftIcon={<Icon icon="ri:arrow-left-line" />}
            variant="secondaryLarge"
          >
            Back
          </Button>
          <Button
            onClick={() => {
              if (currentStep === maxSteps) {
                navigate(`/dashboard/profiles/${profile.id}`);
                return;
              }
              setQueryParams({ ...queryParams, step: currentStep + 1 });
            }}
            rightIcon={<Icon icon="ri:arrow-right-line" />}
            variant="primaryLarge"
          >
            {currentStep === maxSteps ? 'Finish' : 'Next'}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default AddRelatedParty;
