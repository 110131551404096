export type FieldType = 'string' | 'number' | 'boolean' | 'choice' | 'foreign_key';
type Configuration = 'required' | 'optional';

export interface FieldDefinition {
  enabled: boolean;
  configuration: Configuration;
  type: FieldType;
  label: string;
  query?: string;
}

type CategoryFields = Record<string, FieldDefinition>;

type ProfileTypeCategories = Record<string, CategoryFields>;

export interface FieldMetadata {
  IndividualProfile: ProfileTypeCategories;
  EntityProfile: ProfileTypeCategories;
}
export const countryTransform = (data: any): Array<{ label: string; value: string }> =>
  data.map((country: any) => {
    return { label: country.englishName, value: country.englishName };
  });
