import { FieldConfiguration } from '@models/profileTypes';
import { FormField, FormFieldProps, ValidatorType } from './FormField';
import { useGetCountriesQuery } from '@services/canaria.services';
import { type Token } from '@models/profileTypes';
import { useMemo } from 'react';

/* ConfigurableFormField.tsx
 * This component essentially extends the FormField component to do two key things:
 * 1. Enable or disable the field based on the field configuration
 *   This means parsing the configuration for that specific field and evaluating the conditional expression
 *   to determine if the field should be required (if the condition is present)
 * 2. Seemlessly combine the required validator with any other validators passed in for seemless integration
 */

interface ConfigurableFormFieldProps extends FormFieldProps {
  fieldKey: string;
  fieldsConfiguration?: Record<string, FieldConfiguration>;
  isRequiredByDefault?: boolean;
  form: Form;
}

interface FormState {
  values: Record<string, any>;
}

interface Form {
  getState(): { values: Record<string, any> };
}

interface CountryOption {
  label: string;
  value: string;
}

// this literally can't be an interface or types since it needs to be evaluated at runtime
// also always camelCase because of the transform function in RTK Query
// IMPORTANT: if we ever add/modify the profile fields we need to make sure to update this list
const countryFields = ['citizenship', 'countryOfResidence'];

const evaluateOperator = (operator: string, left: any, right: any): boolean => {
  switch (operator) {
    case '==':
      return left == right;
    case '!=':
      return left != right;
    case '>':
      return left > right;
    case '<':
      return left < right;
    case '>=':
      return left >= right;
    case '<=':
      return left <= right;
    case '&&':
      return left && right;
    case '||':
      return left || right;
    case 'and':
      return left && right;
    case 'or':
      return left || right;
    default:
      throw new Error(`Unsupported operator: ${operator}`);
  }
};

/**
 * Evaluates a postfix expression against form values
 *
 * We need this to compute the requiredness of a field based on the conditional expression
 * of it's corresponding field configuration
 *
 * We use the fieldvalues to use the current state of the form to evaluate the expression
 *
 * @param postfix - Array of tokens in postfix notation
 * @param fieldValues - Current form values
 * @param countryOptions - Available country options for translation
 * @returns boolean - Result of evaluation
 */
const evaluate = (postfix: Token[], fieldValues: FormState['values'], countryOptions: CountryOption[]): boolean => {
  // Handle empty or undefined postfix
  if (!postfix?.length) {
    return false;
  }

  // Create a deep copy of field values
  const values = JSON.parse(JSON.stringify(fieldValues));

  // Transform country fields from ID to label
  countryFields.forEach((field) => {
    if (values[field] && countryOptions.length) {
      const country = countryOptions.find((opt) => opt.value === values[field]);
      if (country) {
        values[field] = country.label;
      }
    }
  });

  const stack: any[] = [];
  let isValid = true;

  for (const token of postfix) {
    if (!isValid) break;

    if (token.type === 'field') {
      const camelCaseKey = token.value.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
      let value = values[camelCaseKey];
      if (typeof value === 'object' && value != null && 'label' in value && countryFields.includes(camelCaseKey)) {
        value = value.label;
      }
      if (value === undefined) {
        isValid = false;
        break;
      }
      stack.push(value);
    } else if (token.type === 'value') {
      stack.push(token.value);
    } else if (token.type === 'operator') {
      // Check if we have enough operands
      if (stack.length < 2) {
        isValid = false;
        break;
      }
      const right = stack.pop();
      const left = stack.pop();

      try {
        stack.push(evaluateOperator(token.value, left, right));
      } catch (error) {
        isValid = false;
        break;
      }
    }
  }

  // Check if we have exactly one value in the stack
  if (!isValid || stack.length !== 1) {
    return false;
  }

  return Boolean(stack[0]);
};

const isRequired = (
  fieldConfiguration: FieldConfiguration | undefined,
  isRequiredByDefault: boolean,
  form: Form,
  countryOptions: CountryOption[]
): ValidatorType[] => {
  if (!fieldConfiguration) {
    return isRequiredByDefault ? ['required'] : [];
  }

  const { configuration, conditionalTokens } = fieldConfiguration;

  if (configuration === 'optional') return [];
  if (configuration === 'conditional') {
    return evaluate(conditionalTokens ?? [], form.getState().values, countryOptions) ? ['required'] : [];
  }
  return ['required'];
};

/**
 * A configurable form field that can be enabled or disabled based on the field configuration
 * and can be required or optional based on the field configuration
 * @param fieldKey - The key of the field in the form
 * @param fieldsConfiguration - The configuration of the field
 * @param isRequiredByDefault - Whether the field is required by default
 * @param form - The formik form
 * @param formFieldProps - The props for the form field
 * @returns The form field
 */
export const ConfigurableFormField: React.FC<ConfigurableFormFieldProps> = ({
  fieldKey,
  fieldsConfiguration,
  isRequiredByDefault = false,
  form,
  ...formFieldProps
}) => {
  const fieldConfig = fieldsConfiguration?.[fieldKey];
  const { data: countries } = useGetCountriesQuery(null);
  const countryOptions = countries?.map((country) => ({ label: country.englishName, value: country.id })) ?? [];

  if (fieldConfig?.enabled === false) {
    return null;
  }

  // Since formfield can receive an array of validators that can be strings, objects or functions
  // we craft a list of the received validators and our custom required validator based on the field configuration
  const requiredValidators = useMemo(
    () => isRequired(fieldConfig, isRequiredByDefault, form, countryOptions),
    [fieldConfig, isRequiredByDefault, form, countryOptions]
  );

  const validate = [
    ...(Array.isArray(formFieldProps.validate) ? formFieldProps.validate : [formFieldProps.validate]),
    ...requiredValidators
  ].filter(Boolean);

  return <FormField {...formFieldProps} name={fieldKey} validate={validate as ValidatorType[]} />;
};
