import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useGetProfileGroupsQuery } from '@services/canaria.services';
import {
  SortingState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from '@tanstack/react-table';
import { useState } from 'react';
import { PaginationState } from '@tanstack/react-table';
import CustomTable from '@features/shared/components/CustomTable';
import { Container, Heading, Flex } from '@chakra-ui/react';

const columns = [
  {
    id: 'name',
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => (
      <Box sx={{ maxWidth: '16rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Link to={`/dashboard/settings/wizard/${row.original.id}`} style={{ cursor: 'pointer' }}>
          {row.original.name}
        </Link>
      </Box>
    )
  },
  {
    id: 'tag_name',
    accessorKey: 'tagName',
    header: 'Tag Name'
  },
  {
    id: 'created_at',
    accessorKey: 'createdAt',
    header: 'Created At'
  },
  {
    id: 'updated_at',
    accessorKey: 'updatedAt',
    header: 'Updated At'
  }
];

const ProfileGroups = () => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnOrder, setColumnOrder] = useState(() => columns.map((c) => c.id!));
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25
  });

  const { data: profileGroups, isLoading } = useGetProfileGroupsQuery({
    orgId: activeOrgID as string,
    query: {
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ordering: sorting
        .map((s) => `${s.desc ? '-' : ''}${s.id}`)
        .join(',')
        .replaceAll('.', '__'),
      search: globalFilter
    }
  });

  const table = useReactTable({
    data: profileGroups?.results ?? [],
    columns,
    state: {
      sorting,
      globalFilter,
      pagination,
      columnOrder
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: setColumnOrder,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    pageCount: profileGroups?.count != null ? Math.ceil(profileGroups.count / pagination.pageSize) : -1
  });

  const handleClearSearch = () => {
    setGlobalFilter('');
  };

  return (
    <Container maxW="8xl" px={10}>
      <Flex justifyContent="space-between" mx="4" mb={5}>
        <Heading as="h1" size="lg" textAlign="left" mb={5} fontWeight="normal">
          Wizard Settings
        </Heading>
      </Flex>
      <CustomTable
        table={table}
        data={profileGroups}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        handleClearSearch={handleClearSearch}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default ProfileGroups;
