import { Input, Divider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';

import { StyledSelect } from '@components/common/StyledSelect';
import { ConfigurableFormField } from '@components/ConfigurableFormField';

import PlacePicker from '@features/google-places/PlacePicker';
import {
  useGetCountriesQuery,
  useGetNatureOfEmploymentQuery,
  useGetEntityTypesQuery
} from '@services/canaria.services';
import { USA_STATES } from '@utils/consts';
import { extractAddressDetails } from '@utils/googlePlaces';
import { FieldConfiguration } from '@models/profileTypes';

export const IndividualPrimaryPartyForm: React.FC<{
  form: any;
  fieldsConfiguration?: Record<string, FieldConfiguration>;
}> = ({ form, fieldsConfiguration = {} }) => {
  const { data: countries } = useGetCountriesQuery(null);
  const { data: natureOfEmploymentList } = useGetNatureOfEmploymentQuery(null);
  const hundredYearsAgo = new Date();
  hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);

  const countryOptions = countries?.map((country) => ({ label: country.englishName, value: country.id })) ?? [];

  return (
    <>
      <ConfigurableFormField
        fieldKey="fullLegalName"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="fullLegalName"
        label="Full Legal Name"
      >
        <Input placeholder="Enter full legal name" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="email"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault={false}
        form={form}
        name="email"
        label="Email"
      >
        <Input placeholder="Enter email" />
      </ConfigurableFormField>

      <Divider />

      <ConfigurableFormField
        fieldKey="gender"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault={false}
        form={form}
        name="gender"
        label="Gender"
      >
        <StyledSelect
          options={[
            { label: 'Male', value: 'M' },
            { label: 'Female', value: 'F' }
          ]}
          placeholder="Select gender"
        />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="dateOfBirth"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="dateOfBirth"
        label="Date of Birth"
      >
        {({ input }) => (
          <DatePicker
            {...input}
            selected={input.value != null && input.value !== '' ? dayjs(input.value).toDate() : null}
            onChange={(date) => {
              input.onChange(date != null ? dayjs(date).format('YYYY-MM-DD') : null);
            }}
            showMonthDropdown
            showYearDropdown
            maxDate={new Date()}
            minDate={hundredYearsAgo}
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            customInput={<Input maxW="530px" w="100%" />}
            placeholderText="Select a date"
            dropdownMode="select"
          />
        )}
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="citizenship"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="citizenship"
        label="Citizenship"
      >
        <StyledSelect options={countryOptions} placeholder="Select citizenship" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="address"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault={false}
        form={form}
        name="address"
        label="Address"
      >
        {({ input }) => (
          <PlacePicker
            input={input}
            completeAddress={true}
            onPlaceSelected={(place) => {
              const placeDetails = extractAddressDetails(place);
              if (placeDetails.country !== '') {
                const country = countries?.find((c) => c.alpha2 === placeDetails.country);
                if (country != null) form.change('countryOfResidence', country.id);
              }
              if (placeDetails.city !== '') form.change('city', placeDetails.city);
              if (placeDetails.state !== '') form.change('state', placeDetails.state);
              if (placeDetails.postalCode !== '') form.change('postalCode', placeDetails.postalCode);
              else form.change('postalCode', '');
            }}
          />
        )}
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="countryOfResidence"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="countryOfResidence"
        label="Country of Residence"
      >
        <StyledSelect options={countryOptions} placeholder="Select country" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="state"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="state"
        label="State/Department"
      >
        {({ input }) => {
          const { values } = form.getState();
          const usaId = countries?.find((c) => c.alpha3 === 'USA')?.id;
          const selectedCountry =
            typeof values.countryOfResidence === 'object' ? values.countryOfResidence.value : values.countryOfResidence;
          const isUSA = String(selectedCountry) === String(usaId);

          if (isUSA) {
            return (
              <StyledSelect
                {...input}
                options={USA_STATES.map((state) => ({ label: state, value: state }))}
                placeholder="Select state"
              />
            );
          } else {
            const actualValue = typeof input.value === 'string' ? input.value : input.value.value;
            return <Input {...input} placeholder="Enter state" value={actualValue} />;
          }
        }}
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="city"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="city"
        label="City"
      >
        <Input placeholder="Enter city" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="postalCode"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="postalCode"
        label="Postal Code"
      >
        <Input placeholder="Enter postal code" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="idIssuer"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="idIssuer"
        label="ID Issuer/Country"
      >
        <StyledSelect options={countryOptions} placeholder="Select country" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="idType"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="idType"
        label="ID Type"
      >
        <StyledSelect
          options={[
            { label: 'Passport', value: 'P' },
            { label: 'National ID', value: 'N' },
            { label: 'Driver License', value: 'D' },
            { label: 'Other', value: 'O' }
          ]}
          placeholder="Select ID type"
        />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="idNumber"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="idNumber"
        label="ID Number"
      >
        <Input placeholder="Enter ID number" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="placeOfBirth"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="placeOfBirth"
        label="Place of Birth"
      >
        <StyledSelect options={countryOptions} placeholder="Select country" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="natureOfEmployment"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="natureOfEmployment"
        label="Nature of Employment"
      >
        <StyledSelect
          options={natureOfEmploymentList?.map(({ value, name }) => ({ label: name, value }))}
          placeholder="Select nature of employment"
        />
      </ConfigurableFormField>
    </>
  );
};

export const EntityPrimaryPartyForm: React.FC<{
  form: any;
  fieldsConfiguration?: Record<string, FieldConfiguration>;
}> = ({ form, fieldsConfiguration = {} }) => {
  const { data: countries } = useGetCountriesQuery(null);
  const { data: entityTypes } = useGetEntityTypesQuery(null);

  const countryOptions = countries?.map((country) => ({ label: country.englishName, value: country.id })) ?? [];

  const hundredYearsAgo = new Date();
  hundredYearsAgo.setFullYear(hundredYearsAgo.getFullYear() - 100);
  return (
    <>
      <ConfigurableFormField
        fieldKey="fullLegalName"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="fullLegalName"
        label="Full Legal Name"
      >
        <Input placeholder="Enter full legal name" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="dbaTradeName"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault={false}
        form={form}
        name="dbaTradeName"
        label="DBA/Trade Name"
      >
        <Input placeholder="Enter DBA/Trade name" />
      </ConfigurableFormField>

      <Divider />

      <ConfigurableFormField
        fieldKey="entityFormationType"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="entityFormationType"
        label="Entity Type"
      >
        <StyledSelect
          options={entityTypes?.map(({ value, name }) => ({ label: name, value }))}
          placeholder="Select entity type"
        />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="placeOfEstablishment"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="placeOfEstablishment"
        label="Place of Establishment"
      >
        <StyledSelect options={countryOptions} placeholder="Select country" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="dateOfEstablishment"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="dateOfEstablishment"
        label="Date of Establishment"
      >
        {({ input }) => (
          <DatePicker
            {...input}
            onChange={(date) => {
              input.onChange(date != null ? dayjs(date).format('YYYY-MM-DD') : null);
            }}
            selected={input.value != null && input.value !== '' ? dayjs(input.value).toDate() : null}
            showMonthDropdown
            showYearDropdown
            maxDate={new Date()}
            minDate={hundredYearsAgo}
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            customInput={<Input w="100%" />}
            placeholderText="Select a date"
            dropdownMode="select"
          />
        )}
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="uniqueIdentificationNumber"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="uniqueIdentificationNumber"
        label="Unique Identification Number"
      >
        <Input placeholder="Enter unique identification number" />
      </ConfigurableFormField>

      <ConfigurableFormField
        fieldKey="registeredBusinessAddress"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="registeredBusinessAddress"
        label="Registered Business Address"
      >
        {({ input }) => <PlacePicker input={input} completeAddress={true} />}
      </ConfigurableFormField>

      <Divider />

      <ConfigurableFormField
        fieldKey="mailingAddress"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="mailingAddress"
        label="Mailing Address"
      >
        {({ input }) => <PlacePicker input={input} completeAddress={true} />}
      </ConfigurableFormField>

      <Divider />

      <ConfigurableFormField
        fieldKey="physicalAddress"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault
        form={form}
        name="physicalAddress"
        label="Physical Business Address"
      >
        {({ input }) => <PlacePicker input={input} completeAddress={true} />}
      </ConfigurableFormField>

      <Divider />

      <ConfigurableFormField
        fieldKey="companyWebsite"
        fieldsConfiguration={fieldsConfiguration}
        isRequiredByDefault={false}
        form={form}
        name="companyWebsite"
        label="Company Website"
      >
        <Input placeholder="Enter company website" />
      </ConfigurableFormField>
    </>
  );
};
