import { useSelector } from 'react-redux';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import type { FieldConfiguration } from '@models/profileTypes';
import { useGetProfileByIdQuery } from '@services/canaria.services';

/**
 * useFieldsConfiguration is a hook that returns the fields configuration for a given profile (based on it's
 * profilegroup).
 * This is useful when you need to access the fields configuration for a profile in a component and was created
 * to not have this logic replicated in multiple components.
 * @param profileId The ID of the profile to get the fields configuration for.
 * @returns The fields configuration for the profile.
 */

export const useFieldsConfiguration = (profileId: string): Record<string, FieldConfiguration> => {
  const activeOrgID = useSelector(selectActiveOrgID);
  // here the not null assertion for the activeOrgID is alright because this hook
  // should only be used in the context of a profile that is already loaded
  const { data: profile } = useGetProfileByIdQuery({ orgId: activeOrgID!, profileId });
  if (profile == null) return {};
  return profile?.profileGroup?.fieldsConfiguration?.[profile.resourcetype];
};
